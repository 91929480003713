import React from 'react';

import _ from 'lodash';

import { dt } from '@core/utils/Environment';

import { Icon, Loader } from '@components/dmp';

import { findColumn } from '@components/deal/Columns';
import TooltipButton from '@components/editor/TooltipButton';

export const ACTIONS = {
  UPLOAD: {
    key: 'upload',
    enabled: true,
    title: 'Upload PDFs with metadata attached',
    verb: { present: 'upload', past: 'uploaded' },
    steps: [
      `Identify key metadata to capture from existing ${dt}s (e.g., Party Name, Expiration Date, Annual Value). Note, ${dt}s must be in PDF form.`,
      `If required metadata differs by ${dt} type, sort PDFs accordingly.`,
      `Create one Outlaw template per ${dt} type, with variables and parties defined corresponding to the required metadata.`,
      `Download and populate one csv file per ${dt} type containing the metadata for each PDF you will upload (one ${dt} per row).`,
    ],
    metaInstructions: 'Click the table or drag and drop PDFs with filenames matching those in your metadata',
  },
  CREATE: {
    key: 'create',
    enabled: true,
    title: `Generate ${dt}s from existing template`,
    verb: { present: 'generate', past: 'generated' },
    steps: [
      `Generate multiple new ${dt}s at once from the same template`,
      `New ${dt}s are populated with data via prepared comma-separated-value (csv) file`,
      `Each csv row contains data for a different ${dt} to be created`,
      `All resulting new ${dt}s will appear in repository populated with their unique data`,
    ],
  },
  UPDATE: {
    key: 'update',
    title: `Update metadata for existing ${dt}s`,
    verb: { present: 'update', past: 'updated' },
    steps: [
      `Only one ${dt} type can be importated at a time`,
      `Legacy ${dt} files in PDF format`,
      'Populate metadata with matching filenames',
    ],
  },
  DELETE: {
    key: 'delete',
    title: `Delete existing ${dt}s`,
    verb: { present: 'delete', past: 'deleted' },
    steps: [
      `Only one ${dt} type can be importated at a time`,
      `Legacy ${dt} files in PDF format`,
      'Populate metadata with matching filenames',
    ],
  },
};

export const PROCESS_STATUS = {
  MISSING: {
    key: 'missing',
    icon: <Icon name="nope" />,
    tip: 'Awaiting matching PDF file...',
  },
  READY: {
    key: 'ready',
    icon: <Icon name="check" />,
    tip: 'Ready to process',
  },
  UPLOADING: {
    key: 'uploading',
    icon: <Loader />,
    tip: 'Operation in progress...',
  },
  DONE: {
    key: 'done',
    icon: <Icon name="check" />,
    tip: 'Operation completed successfully',
  },
  DATA_ERROR: {
    key: 'dataError',
    icon: <Icon name="error" />,
    tip: 'Row contains parties or variables that are not included in template',
  },
  STATUS_ERROR: {
    key: 'statusError',
    icon: <Icon name="error" />,
    tip: 'Status not specified',
  },
  ERROR: {
    key: 'error',
    icon: <Icon name="error" />,
    tip: 'An error occurred',
  },
};

const FILE_COLUMN = {
  key: 'filename',
  Header: 'Filename',
  accessor: 'filename',
  minWidth: 300,
  className: 'title',
  Cell: ({ original: metaCSV }) => <div className="deal-filename">{metaCSV.filename}</div>,
};

const TITLE_COLUMN = {
  key: 'title',
  Header: 'Title',
  accessor: 'title',
  minWidth: 200,
  className: 'title',
  Cell: ({ original: metaCSV }) => <div className="deal-title">{metaCSV.title}</div>,
};

const PROCESS_COLUMN = {
  key: 'process',
  Header: '',
  accessor: 'process',
  width: 40,
  className: 'process',
  Cell: ({ original: metaCSV, index }) => {
    const displayStatus = _.find(PROCESS_STATUS, { key: metaCSV.processStatus }) || PROCESS_STATUS.MISSING.key;
    return (
      <div className="deal-process" data-cy="deal-process">
        <TooltipButton tipID={`tip-csv-${index}`} tip={displayStatus.tip}>
          {displayStatus.icon}
        </TooltipButton>
      </div>
    );
  },
  fixed: 'left',
  sortable: false,
  clickable: false,
  resizable: false,
};

export const getColumns = (action, template, connections = []) => {
  const variables = _.get(template, 'filterableVariables', []);
  const parties = _.get(template, 'parties', []);

  const tableColumns = [];

  tableColumns.push(PROCESS_COLUMN);

  if (action === 'upload') {
    tableColumns.push(findColumn('status', template, 'report'));
    tableColumns.push(FILE_COLUMN);
  }

  tableColumns.push(TITLE_COLUMN);

  if (parties.length > 0) {
    tableColumns.push(_.merge(findColumn('parties', template), { minWidth: 300 }));
  }

  _.forEach(variables, (variable) => {
    const column = findColumn(`v.${variable.name}`, template, 'report');

    if (column) {
      column.sortable = false;
      const templateVariable = template.variables[variable.name];
      if (!templateVariable) return;

      tableColumns.push({
        ...column,
        Header: templateVariable.displayName || templateVariable.name,
        id: column.key,
        accessor: (meta) => _.get(_.find(meta.variables, { name: variable.name }), 'value'),
        className: `variable variable-${variable.name}`,
      });
    }
  });

  // Build connections from the first row
  _.forEach(connections[0], (ids, type) => {
    tableColumns.push({
      sortable: false,
      Header: `+${type}`,
      id: `+${type}`,
      accessor: (meta) => _.get(meta.connections?.[type], 'id'),
      className: `connection connection-${type}`,
    });
  });

  return tableColumns;
};

export const trProps = (state, { original: metaCSV }) => {
  return { className: metaCSV.processStatus };
};
